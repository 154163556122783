<template>
  <div class="grid">
    <div class="body p-3">
      <h1 class="mt-4">{{ message }}</h1>

      <img
        class="calendar w-100 mt-5"
        src="@/assets/img/calendar.jpg"
        alt="calendar"
      />

      <p class="mt-3">
       {{ description }}
      </p>
    </div>
  </div>
</template>
  
<script>
import AppointmentService from "@/services/resources/AppointmentService";
const service = AppointmentService.build();
export default {
  data(){
    return {
      message: "",
      description: ""
    }
  },
  methods: {
    cancelAppointment() {
      service
        .read({ id: `cancel-appointment?tk=${this.$route.query.tk}` })
        .then(() => {
          this.message =  "Sua reunião foi cancelada!"
          this.description = "Obrigado pelo cancelamento, iremos informar o usuário. 🙂";
        })
        .catch(() => {
          this.message = "Ops!";
          this.description = "Pareca que a reunião já foi realizada ou cancelada.";
        });
    },
  },
  created() {
    this.cancelAppointment();
  },
};
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  place-items: center;
  height: 90vh;
  overflow: none;
}
.body {
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  h1 {
    color: var(--greenn-dark);
    font-weight: 500;
    font-size: 2.5em;
  }
  p {
    color: var(--greenn);
    font-size: 18px;
  }
}

@media screen and (max-width: 650px) {
  h1 {
    font-size: 1.5em !important;
  }
}
</style>
  